
import React from 'react';
import CryptoJS from 'crypto-js';

const key = '0xa88b38e9f80e0dfe878b089e01c614a8';
function encryptData(object) {
    const encryptedObj = {};

    // Loop through each property of the input object
    for (const prop in object) {
      // Encrypt the property value using AES encryption
      const encryptedValue = CryptoJS.AES.encrypt(object[prop], key).toString();
  
      // Add the encrypted value to the new object
      encryptedObj[prop] = encryptedValue;
    }
  
    return encryptedObj;
  }
export default encryptData;