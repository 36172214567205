import React from "react";
import { useState } from "react";
import ModalForm from "./ModalForm";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {MenuBook } from "@mui/icons-material";

const medicalBilling = [
  {
    title: 'Medical Billing Assistance',
    img: "assets/images/r1.webp",
    description: 'Expert Medical Billing: Trust us to handle the heavy load of paperwork and insurance billing standards in your state, allowing you to focus on patient care.'
  },
  {
    title: 'Charge Capture',
    img: "assets/images/r2.webp",
    description: 'Timely Claim Submission: Claims and charges are entered promptly within 24 hours of receiving superbills, ensuring swift processing.'
  },
  {
    title: 'Claims Scrubbing',
    img: "assets/images/r3.webp",
    description: 'Error-Free Claims: Our thorough audit and scrubbing process ensures clean, error-free, and compliant claims, following coding guidelines.'
  },
  {
    title: 'Rejection Management',
    img: "assets/images/r4.webp",
    description: 'Swift Corrections: Our experts analyze denials and take corrective actions to prevent recurring issues, ensuring that claims are processed smoothly.'
  },
  {
    title: 'Payment Posting',
    img: "assets/images/r5.webp",
    description: 'Efficient Payment Handling: We post payments from electronic remittances, paper vouchers, and patient payments to respective patient ledgers.'
  },
  {
    title: 'Denial Management',
    img: 'assets/images/r6.webp',
    description: 'Optimizing Reimbursement: We dispute and file appeals for denied claims, working diligently to secure the payments you deserve.'
  },
  {
    title: 'Appeals and Underpayment Disputes',
    img: "assets/images/r7.webp",
    description: 'Advocating for You: Our team goes the extra mile to dispute and file appeals, ensuring you receive the appropriate compensation.'
  },
  {
    title: 'Complete Claim Follow-Up',
    img: 'assets/images/r8.webp',
    description: "Recover What's Due: Our specialists follow up on outstanding claims to recover reimbursements owed by insurers."
  },
  {
    title: 'Patient Billing and Statements',
    img: 'assets/images/r9.webp',
    description: 'Transparent Patient Communication: Notify patients about their financial responsibilities, including copays, deductibles, and coinsurance, after insurance payments and settlements.'
  },
  {
    title: 'Balance Reminder Calls',
    img: 'assets/images/r10.webp',
    description: 'Timely Reminders: Send polite balance reminder calls to patients after the statement cycle, helping them stay informed about their outstanding balances.'
  },
  {
    title: 'Patient Inquiries',
    img: `assets/images/r11.webp`,
    description: 'Responsive Patient Support: Our patient desk representatives address inquiries promptly while adhering to ethical phone etiquette.'
  },
  {
    title: 'Financial and Practice Analysis Reporting',
    img: 'assets/images/r12.webp',
    description: "Informed Decision-Making: Access scheduled analysis and customized reporting to gain insights into your practice's performance."
  },
]

function MedicalBilling() {
  const [show, setShow] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const handleShow = () => setShow(true);
  const handleExpand = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };
  return (
    <div className="Medical_Billing">
      <div className="Medical_Billing_head">
        <h2>Medical Billing</h2>
        <p>
          At MIAMI BEACH Medical Billing, we recognize that managing the financial aspects of your medical practice can be a daunting task. Our Medical Billing services are designed to be your trusted partner in maintaining the financial health of your practice. We understand the intricate details of insurance billing standards, paperwork, and the ever-evolving healthcare landscape. With our expertise and dedication, we aim to alleviate the burden of medical billing, allowing you to concentrate your valuable time and energy on delivering exceptional patient care. Whether it's charge capture, claims management, denial resolution, or comprehensive financial analysis, we've got you covered. Let us handle the complexities of medical billing while you focus on what matters most: the well-being of your patients
        </p>
      </div>

      <ul className="Medical_Billing_cards_row1 row">
        {
          medicalBilling.map((x, i) =>
            <div key={i} onClick={() => handleExpand(i)} className="mb-3 col-md-3 medical_bill" style={{ backgroundImage: `url(${x.img})`, margin: 0, padding: 0 }}>
              <div className={`Medical_Billing_card `}  >
                <li>{x.title}</li>
                <IconButton component='p' ><MenuBook /> </IconButton>
                {/* <p> {x.description.length > 30 ? `${x.description.slice(0, 30)}... Read More` : x.description}</p> */}
              </div>
            </div>
          )
        }
      </ul>
      <div className="btn_wrapper">
        <button className="btn_10 mb-3" onClick={handleShow}>
          <span>FREE CONSULTATION</span>
        </button>
      </div>
      {medicalBilling.map((x, i) => (
        <Dialog open={expandedItem === i} className="medical_dialog" maxWidth='xs' onClose={() => setExpandedItem(null)} key={i}>
          <DialogTitle component={'h3'}>
            {x.title}
          </DialogTitle>
          <img src={x.img} />
          <DialogContent component={'p'}  >{x.description}</DialogContent>
          <DialogActions component='button' color="primary" >Start Now</DialogActions>
        </Dialog>
      ))}
      <ModalForm show={show} />
    </div >
  );
}

export default MedicalBilling;
