import { Box } from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, } from '@mui/material/styles';
import { green, } from '@mui/material/colors';
import { Button, FormControl, TextField, Typography, LinearProgress, Step, StepContent, StepLabel, Stepper, Grid, Divider, Select, InputLabel, MenuItem, IconButton, Alert, Accordion, AccordionSummary, AccordionDetails, Backdrop, CircularProgress, AlertTitle } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import encryptData from './encrypt';
import { DarkMode, LightMode,ReplaySharp,CheckCircleOutlineSharp } from '@mui/icons-material';
import 'react-phone-number-input/style.css';
import Footer from "../assets/images/mbmb-black.webp";
import 'firebase/auth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const firebaseConfig = {
    apiKey: "AIzaSyBvdd19MIU-KD9q5T75uEK2-SAWzGrBLoA",
    authDomain: "mbmb-98c42.firebaseapp.com",
    databaseURL: "https://mbmb-98c42-default-rtdb.firebaseio.com",
    projectId: "mbmb-98c42",
    storageBucket: "mbmb-98c42.appspot.com",
    messagingSenderId: "8947764661",
    appId: "1:8947764661:web:2eb5d0d212f651ee7dddfc"
};
firebase.initializeApp(firebaseConfig);





export default function Form() {
    const [personal, setPersonal] = React.useState({})       //Billing > Personal
    const [services, setServices] = React.useState({})       //Billing > Services
    const [billing, setBilling] = React.useState({})         //Billing > Billing
    const [corres, setCorres] = React.useState({})           //Billing > Corres
    const [agreement, setAgreement] = React.useState({})     //Agreement
    const [business, setBusiness] = React.useState({})       //Client > Business
    const [management, setManagement] = React.useState({})   //Client > Management
    const [emails, setEmails] = React.useState({})           //Client > Emails
    const [billForm, setBillForm] = React.useState(0);
    const [clientForm, setClientForm] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [otherToggle, setOtherToggle] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [errorrs, setErrorrs] = React.useState(false);
    const [dark, settDark] = React.useState(false);

    const theme = createTheme({
        palette: {
            text: {
                main: '#fff',
                contrastText: green[50]
            },
            primary: {
                main: '#d5278faa  ',
                contrastText: '#CCCCCC'
            }
        },
    });

    function LinearProgressWithLabel(props) {
        let a = Math.round(props.value * 100 / props.total,)
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }} >

                <Box sx={{ width: `${a}%`, m: 0, mr: 1, p: 0, }}>
                    <LinearProgress variant="determinate" color='primary' value={a} sx={{ width: 'auto', height: '1rem', }} />
                </Box>
                <Box sx={{ width: 35, height: 'auto', p: 0, }}>
                    <Typography variant="body2" sx={{ fontSize: '1rem', color: !dark ? '#000' : '#fff' }}>{`${a}%`}</Typography>
                </Box>
            </Box>
        );
    }

    LinearProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate and buffer variants.
         * Value between 0 and 100.
         */
        value: PropTypes.number.isRequired,
    };

    const submitBill = (personal, services, billing, corres) => {
        setSubmit(true)
        const database = firebase.database();

        let bills = { ...personal, ...services, ...billing, ...corres }
        bills = encryptData(bills)

        database.ref('bills').push(bills)
            .then(() => {
                setSuccess(true);
            })
            .catch((error) => {
                setErrorrs(true);
                setErrorMsg(error.message);
            });


    }
    const submitAgree = (agreement) => {
        setSubmit(true)

        const database = firebase.database();

        let agree = { ...agreement }
        agree = encryptData(agree);
        
        
    
        database.ref('agree').push(agree)
            .then(() => {
                setSuccess(true) ;
            })
            .catch((error) => {
                setErrorrs(true);
                setErrorMsg(error.message);
            });
            

    }
    const submitClient = ( business, management, emails) => {
        setSubmit(true)

        const database = firebase.database();

        
        let clients = { ...business, ...management, ...emails }
        clients = encryptData(clients)
        

        
        database.ref('clients').push(clients)
            .then(() => {
                setSuccess(true);
            })
            .catch((error) => {
                setErrorrs(true);
                setErrorMsg(error.message);
            });


    }
    const styles = {
        textfield: {
            color: '#fff'
        },
        inline: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '8px',
        }
    }


    const checkLength = (obj, n) => Object.keys(obj).length >= n ? false : true;

    return (

        <ThemeProvider theme={theme} >

            <Grid container sx={{
                width: 'auto', m: 0, p: 0, minHeight: '100vh',
                backgroundColor: !dark ? '#fff' : '#212121'
            }}>

                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: '140px', mt: '-35px', background: 'linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%)', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item sx={{ mt: '35px' }}>

                        <img src={Footer} alt="" style={{ width: '200px', height: 'auto', }} />
                    </Grid>

                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                    <Grid item sx={{ mt: '10px', ml: '10px', }}>
                        <IconButton
                            edge='start' aria-label="change Theme" color="primary"
                            onClick={() => settDark(!dark)}

                        >
                            {dark ? <DarkMode sx={{ fontSize: '40px' }} /> : <LightMode sx={{ fontSize: '40px' }} />}
                        </IconButton>
                    </Grid>

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ minHeight: 'auto', m: 10, mt: 0 }}>

                    <Box sx={{ maxWidth: '1200px', ml: 'auto', mr: 'auto' }} >

                        <Accordion sx={{ m: 2, background: 'linear-gradient(90deg, #d5278f99 0%, #2f3d95ee 95.61%)', }} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography component='h4' sx={{ fontSize: "2rem", color: dark ? '#fff' : '#000', fontFamily: 'Sofia', p: 2 }} >{`BILLING`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box color="#fff0" height={"max-content"} sx={{ mr: 'auto', ml: 'auto', p: 2, width: 'auto', maxWidth: '720px', overflow: 'auto' }}   >

                                    <Stepper activeStep={billForm} orientation='vertical'  >

                                        <Step key={"PERSONAL"}  >
                                            <StepLabel >
                                                <Typography component='h4' sx={{ fontSize: "2rem", color: dark ? '#fff' : '#000', fontFamily: 'Sofia', p: 2 }} >PERSONAL</Typography>
                                            </StepLabel>

                                            <StepContent>
                                                {/* <Divider sx={{ mt: 10, }} ><Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>PERSONAL</Typography></Divider> */}

                                                <Grid container spacing={2} rowSpacing={1} sx={{
                                                    width: 'auto', m: 0, overflow: 'auto',
                                                }}>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Full Name" name="Full Name" label="Full Name" value={personal["Full Name"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Full Name'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Date of Birth" name="Date of Birth" label="Date of Birth" value={personal["Date of Birth"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Date of Birth'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                                        <FormControl fullWidth >
                                                            <InputLabel variant="standard" style={{ color: dark && '#fffa' }} htmlFor="type">
                                                                Request
                                                            </InputLabel>
                                                            <Select
                                                                InputLabelProps={dark && { style: { color: 'white' } }}
                                                                id="Request Type"
                                                                name='Request'
                                                                style={{ color: dark && 'white' }}
                                                                variant="standard"
                                                                onChange={(e) => { setPersonal({ ...personal, [`${e.target.name}`]: e.target.value }) }}
                                                                InputProps={{
                                                                    name: 'Request',
                                                                    id: 'Request',
                                                                    placeholder: 'Request',
                                                                    style: { color: dark && 'white' }
                                                                }}
                                                            >
                                                                <MenuItem value={"Credential"} selected>Credential</MenuItem>
                                                                <MenuItem value={"Re-Credential"}>Re-Credential</MenuItem>
                                                            </Select>
                                                        </FormControl></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                                        <FormControl fullWidth >
                                                            <InputLabel variant="standard" style={{ color: dark && '#fffa' }} htmlFor="type">
                                                                Provider Type
                                                            </InputLabel>
                                                            <Select
                                                                InputLabelProps={dark && { style: { color: 'white' } }}
                                                                style={{ color: dark && 'white' }}
                                                                variant="standard"
                                                                name='Provider Type'
                                                                onChange={(e) => { setPersonal({ ...personal, [`${e.target.name}`]: e.target.value }); e.target.value === 'Other' ? setOtherToggle(true) : setOtherToggle(false); }}
                                                                InputProps={{
                                                                    name: 'Provider Type',
                                                                    id: 'Provider Type',
                                                                    placeholder: 'Provider Type',
                                                                    style: { color: 'white' }
                                                                }}
                                                            >
                                                                <MenuItem value={"Physical Therapist"} selected>Physical Therapist</MenuItem>
                                                                <MenuItem value={"Physician Assistant"}>Physician Assistant</MenuItem>
                                                                <MenuItem value={"Practitioner"}>Practitioner</MenuItem>
                                                                <MenuItem value={"Physician"}>Physician</MenuItem>
                                                                <MenuItem value={"Nurse"}>Nurse</MenuItem>
                                                                <MenuItem value={"Other"}>Other</MenuItem>
                                                            </Select>

                                                        </FormControl></Grid>
                                                    {otherToggle && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><TextField
                                                        fullWidth
                                                        name={`Provider Type Other`}
                                                        variant='standard'
                                                        placeholder={"Type here."}
                                                        label="Please Specify Type"
                                                        InputProps={dark && { style: styles.textfield }}
                                                        InputLabelProps={dark && { style: styles.textfield }}
                                                        color={dark && 'text'}
                                                        onChange={(e) => { setPersonal({ ...personal, [`${e.target.name}`]: e.target.value }) }}
                                                        sx={{ m: 0, p: 0, }} /></Grid>}
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Provider SSN" name="Provider SSN" label="Provider SSN" value={personal["Provider SSN"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Provider SSN'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Provider NPI" name="Provider NPI" label="Provider NPI" value={personal["Provider NPI"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Provider NPI'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Group NPI" name="Group NPI" label="Group NPI" value={personal["Group NPI"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Group NPI'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Practice Name" name="Practice Name" label="Practice Name" value={personal["Practice Name"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Practice Name'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Practice Specialty" name="Practice Specialty" label="Practice Specialty" value={personal["Practice Specialty"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Practice Specialty'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Home Address" name="Home Address" label="Home Address" value={personal["Home Address"]} onChange={(e) => setPersonal({ ...personal, [e.target.name]: e.target.value })} placeholder='Home Address'   multilines="true" fullWidth /></Grid>
                                                </Grid>
                                                <Box sx={{ mb: 2, p: 2, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        color={'primary'}
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={checkLength(personal, 10)}
                                                        onClick={() => { setBillForm(prev => prev + 1); }}
                                                    >
                                                        <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                            Next
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                        <Step key={"SERVICES"}  >
                                            <StepLabel >
                                                <Typography component='h4' sx={{ fontSize: "2rem", color: dark ? '#fff' : '#000', fontFamily: 'Sofia', p: 2 }} >SERVICES</Typography>
                                            </StepLabel>

                                            <StepContent>
                                                {/* <Divider sx={{ mt: 10 }} ><Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>SERVICES</Typography></Divider> */}
                                                <Grid container spacing={2} rowSpacing={1} sx={{
                                                    width: 'auto', m: 0, overflow: 'auto',
                                                }}>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                                        <TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Service Phone" name="Service Phone" label="Phone" value={services["Service Phone"]} onChange={(e) => setServices({ ...services, [e.target.name]: e.target.value })} placeholder='Service Phone'   fullWidth />

                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Service Fax Number" name="Service Fax Number" label="Fax Number" value={services["Service Fax Number"]} onChange={(e) => setServices({ ...services, [e.target.name]: e.target.value })} placeholder='Service Fax Number'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Work Hours Details" name="Work Hours Details" label="Work Hours Details" value={services["Work Hours Details"]} onChange={(e) => setServices({ ...services, [e.target.name]: e.target.value })} placeholder='Work Hours Details'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Service Location Address" name="Service Location Address" label="Location Address" value={services["Service Location Address"]} onChange={(e) => setServices({ ...services, [e.target.name]: e.target.value })} placeholder='Service Location Address'   multilines="true" fullWidth /></Grid>
                                                </Grid>
                                                <Box sx={{ mb: 2, p: 2, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        color={'primary'}
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={checkLength(services, 4)}
                                                        onClick={() => { setBillForm(prev => prev + 1); }}
                                                    >
                                                        <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                            Next
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                        <Step key={"BILLING"}  >
                                            <StepLabel >
                                                <Typography component='h4' sx={{ fontSize: "2rem", color: dark ? '#fff' : '#000', fontFamily: 'Sofia', p: 2 }} >BILLING</Typography>
                                            </StepLabel>

                                            <StepContent>
                                                {/* <Divider sx={{ mt: 10 }} ><Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>BILLING</Typography></Divider> */}
                                                <Grid container spacing={2} rowSpacing={1} sx={{
                                                    width: 'auto', m: 0, overflow: 'auto',
                                                }}>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Billing Phone" name="Billing Phone" label="Phone" value={billing["Billing Phone"]} onChange={(e) => setBilling({ ...billing, [e.target.name]: e.target.value })} placeholder='Billing Phone'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Billing Fax Number" name="Billing Fax Number" label="Fax Number" value={billing["Billing Fax Number"]} onChange={(e) => setBilling({ ...billing, [e.target.name]: e.target.value })} placeholder='Billing Fax Number'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Billing Location Address" name="Billing Address" label="Address" value={billing["Billing Address"]} onChange={(e) => setBilling({ ...billing, [e.target.name]: e.target.value })} placeholder='Billing Address'   multilines="true" fullWidth /></Grid>
                                                </Grid>
                                                <Box sx={{ mb: 2, p: 2, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        color={'primary'}
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={checkLength(billing, 3)}
                                                        onClick={() => { setBillForm(prev => prev + 1); }}
                                                    >
                                                        <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                            Next
                                                        </Typography>
                                                    </Button>
                                                </Box>

                                            </StepContent>
                                        </Step>
                                        <Step key={"CORRESPONDENCE"}  >
                                            <StepLabel >
                                                <Typography component='h4' sx={{ fontSize: "2rem", color: dark ? '#fff' : '#000', fontFamily: 'Sofia', p: 2 }} >CORRESPONDENCE</Typography>
                                            </StepLabel>

                                            <StepContent>
                                                {/* <Divider sx={{ mt: 10 }} ><Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>CORRESPONDENCE</Typography></Divider> */}
                                                <Grid container spacing={2} rowSpacing={1} sx={{
                                                    width: 'auto', m: 0, overflow: 'auto',
                                                }}>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Correspondence Phone" name="Correspondence Phone" label="Phone" value={corres["Correspondence Phone"]} onChange={(e) => setCorres({ ...corres, [e.target.name]: e.target.value })} placeholder='Correspondence Phone'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Correspondence Fax Number" name="Correspondence Fax Number" label="Fax Number" value={corres["Correspondence Fax Number"]} onChange={(e) => setCorres({ ...corres, [e.target.name]: e.target.value })} placeholder='Correspondence Fax Number'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Correspondence Location Address" name="Correspondence Address" label="Address" value={corres["Correspondence Address"]} onChange={(e) => setCorres({ ...corres, [e.target.name]: e.target.value })} placeholder='Correspondence Address'   multilines="true" fullWidth /></Grid>

                                                </Grid>
                                                <Box sx={{ mb: 2, p: 2, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        color={'primary'}
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={checkLength(corres, 3)}
                                                        onClick={() => { setBillForm(prev => prev + 1); }}
                                                    >
                                                        <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                            Next
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </StepContent>

                                        </Step>
                                    </Stepper>
                                    <Box sx={{ m: 5, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                        <Button variant='contained' fullWidth
                                            disabled={checkLength(personal, 10) || checkLength(services, 4) || checkLength(billing, 3) || checkLength(corres, 3) }
                                            onClick={() => submitBill(personal, services, billing, corres)}>
                                            <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                Submit
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <LinearProgressWithLabel value={billForm} total={4} color={'primary'} />
                            </AccordionDetails>
                        </Accordion>


                        <Accordion sx={{ m: 2, background: 'linear-gradient(90deg, #d5278f99 0%, #2f3d95ee 95.61%)', }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography component='h4' sx={{ fontSize: "2rem", color: dark ? '#fff' : '#000', fontFamily: 'Sofia', p: 2 }} >{`AGREEMENT`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>



                                <Box color="#fff0" height={"max-content"} sx={{ mr: 'auto', ml: 'auto', p: 5, width: 'auto', maxWidth: '1080px', minHeight: '60vh', maxHeight: '640px', overflow: 'auto' }}   >
                                    <Grid container spacing={2} rowSpacing={1} sx={{
                                        width: '100%', m: 0, overflow: 'auto',
                                    }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <Typography sx={{ color: dark ? '#fff' : '#000', lineHeight: 2.2 }} >
                                                THIS AGREEMENT FOR Medical Billing Services ("Agreement") is made today,<TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Date" name="Agreement Date" value={agreement["Agreement Date"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Agreement Date' sx={{ ml: 1, mr: 1, width: 'fit-content' }}   />
                                                (the "Effective Date"), between Miami Beach Medical Billing LLC and  <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Name" name="Name" value={agreement["Name"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Name' sx={{ ml: 1, mr: 1, width: 'fit-content' }}   /> (“Client”).
                                                <br /><br />WHEREAS, Miami Beach Medical Billing is a medical billing services company which provides computerized claims, billing and collection services to healthcare providers; and
                                                <br /><br />WHEREAS, Client wishes to retain Miami Beach Medical Billing to provide for medical billing services (“Services”) pursuant to the terms and conditions of this Agreement.
                                                <br /><br />NOW, THEREFORE, in consideration of the foregoing promises and mutual covenants set forth herein, the parties agree as follows
                                                <br /><br />1. <strong>MEDICAL BILLING SERVICES.</strong>
                                                <br /><br />Client hereby expressly authorizes Miami Beach Medical Billing to prepare, process and submit claims to commercial and government payors. Miami Beach Medical Billing will apply its best efforts to obtain reimbursement for Client’s charges for all clinical procedures and medical services provided to patients. All such claims shall be submitted by Miami Beach Medical Billing in the Client’s name and utilize provider numbers assigned to the Client by the respective third-party payor. Claims will be submitted either electronically or by means of paper submission as directed by the third-party payor. Payment of all claims filed on behalf of Client shall be directed to such accounts to which Client has control as may be required by third party payors. EchoScribe shall post payments received from the third-party payors to the patient's file and may bill the patient directly when necessary in order to secure full payment for the Client.
                                                <br /><br />2. <strong>CLIENT RESPONSIBILITIES</strong>
                                                <br /><br />2.1. Reasonable Assistance. Client agrees to provide Miami Beach Medical Billing with all necessary records, information and assistance to enable Miami Beach Medical Billing to provide such Services. Client shall be responsible for ensuring the accuracy and completeness of coding associated with claims and for ensuring the medical necessity and appropriateness of the Services for which a bill will be submitted. The client also agrees to promptly provide Miami Beach Medical Billing all copies of all Explanation of Benefits forms received from third party payors as well as records of payments received directly from patients.
                                                <br /><br />2.2. Systems Access.  Client hereby grants to Miami Beach Medical Billing the right to access and use its practice management software to facilitate the submission of medical claims. All patient information and data provided by the Client to Miami Beach Medical Billing shall be kept confidential and shall only be disclosed to parties necessary to successfully process and submit claims on behalf of the Client.
                                                <br /><br />2.3. Client Acknowledgement. Client acknowledges and agrees to the following:
                                                <br /><br />2.3.1. The ultimate responsibility for all claims submitted is that of the Client. Client shall be responsible for maintaining all original source documents to enable it to verify and document the claims submitted to third party payors. EchoScribe shall have no liability or responsibility whatsoever regarding the accuracy or completeness of the coding or determinations regarding medical necessity.
                                                <br /><br />3. <strong>FEES</strong>
                                                <br /><br />3.1. Setup Costs. There is no charge for the initial set up of the medical billing services.
                                                <br /><br />3.2. Net Collections. Client shall pay Miami Beach Medical Billing for medical billing services based on its total dollar amount of <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Amount" name="Amount" value={agreement["Amount"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Amount' sx={{ ml: 1, mr: 1, width: 'fit-content' }}   />
                                                monthly net collections. Client agrees to pay Miami Beach Medical Billing a rate of <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Rate" name="Rate" value={agreement["Rate"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Rate' sx={{ ml: 1, mr: 1, width: 'fit-content' }}   />
                                                of monthly net collections. Past due A/R collection services will be charged at the same rate.
                                                <br /><br />3.3. Patient Statements. If client wants Miami Beach Medical Billing to print and mail patient statements on its behalf, then client agrees to reimburse Miami Beach Medical Billing $1.00 per mailed patient statement.
                                                <br /><br />3.4. Terms of Payment. Miami Beach Medical Billing shall invoice Client at the beginning of each month for the prior month’s Services by electronic or US mail.  The invoice will summarize the total amount due based on the net collections for the month being invoiced. Payments are due within thirty (30) days of receipt of invoice.
                                                <br /><br />Delinquent payment is subject to a late fee in the amount of $50.00 per delinquent month or 2% monthly interest, whichever is greater. Client agrees to pay Miami Beach Medical Billing via bank <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={'text'} id="Bank" name="Bank" value={agreement["Bank"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Bank' sx={{ ml: 1, mr: 1, width: 'fit-content' }}   /> auto-pay.
                                                <br /><br />3.5. Disputed Fees. Should Client dispute any portion of the invoice, Client must notify Miami Beach Medical Billing within twenty (20) days of the date of invoice by emailing Jasmel@medicalbillingmb.com. Failure to notify Miami Beach Medical Billing within this time-frame shall be deemed acceptance to pay Miami Beach Medical Billing in full for the invoice. Payment of invoice shall also be deemed as acceptance of charges.
                                                <br /><br />4. <strong>CONFIDENTIALITY AND HIPAA</strong>
                                                <br /><br />4.1. Business Associate Agreement. Miami Beach Medical Billing and Client will abide by the covenants and provisions of the “Business Associates Agreement,” which is incorporated herein by reference.
                                                <br /><br />5. <strong>TERM AND TERMINATION</strong>
                                                <br /><br />5.1. Term. This Agreement shall remain in effect until terminated by either party. This Agreement may be terminated if there is a breach in contract or for simple convenience. The terminating party is responsible to provide a forty-five (45) day written notice to the other party along with an explanation for termination.
                                                <br /><br />5.2. Rights on Termination. Upon termination, Client shall be permitted to terminate Miami Beach Medical Billing’s access to its systems. Client shall be required to pay all fees accrued as of the date of termination plus any claims or charges that are pending or in-transit.
                                                <br /><br />6. <strong>LIMITATIONS OF WARRANTY AND LIABILITY</strong>
                                                <br /><br />6.1. Limited Warranty.  Other than the foregoing limited warranty, all Services are provided "as is," without any warranty whatsoever, whether express or implied, including but not limited to the warranties of merchantability and fitness for a particular purpose.
                                                <br /><br />6.2. Limitation of Liability.
                                                <br /><br />6.2.1. Client hereby agrees to indemnify and hold Miami Beach Medical Billing and its owners, directors, employees and contractors harmless from and against any and all liability, claims, causes of action, damages, fines, assessments, penalties, costs (including reasonable attorney fees) and responsibility of any kind arising from the performance or non-performance of this agreement or any acts or omissions associated. Client shall holdMiami Beach Medical Billing and its owners, directors, employees and contractors harmless from and against any claims submitted on behalf of and in the name of the Client for which client has provided Miami Beach Medical Billing false, fraudulent, incomplete, misleading, or otherwise incorrect information or data, including but not limited to the coding of claims pursuant to Section 2.1 above.
                                                <br /><br />6.2.2. To the maximum extent permitted by applicable law, in no event shall either party be liable for special, indirect, incidental, punitive or consequential damages, whether arising under contract, warranty, or tort (including negligence or strict liability) or any other theory of liability.
                                                <br /><br />6.2.3. The submission of false, fraudulent or misleading data, information, or statements to the government and/or commercial third party payors in connection with health insurance coding, billing and claims submission is a crime and can subject the violator to imprisonment and fines.
                                                <br /><br />6.2.4. Client expressly acknowledges and agrees that in no case shall Miami Beach Medical Billing’s liability for damages, regardless of the form of the action, exceed the amount of fees paid by client hereunder during the most recent three (3) month period immediately preceding the event giving rise to the claim. Notwithstanding anything to the contrary contained in this Agreement, neither party shall be liable to the other for any third-party claims even if a party has been apprised of the likelihood of such damages.
                                                <br /><br />6.2.5. The parties will not incur liability to each other for failing to perform any obligation under this Agreement if such failure results from a force majeure event or any force beyond their reasonable control.
                                                <br /><br />7. <strong>GENERAL</strong>
                                                <br /><br />7.1. Choice of Law and Jurisdiction. This agreement is governed by the laws of the State of Florida, without regard to their conflict of law provisions.
                                                <br /><br />7.2. Entire Agreement. This Agreement constitutes the entire agreement and understanding between the parties with respect to the subject matter of this Agreement and supersedes all prior agreements or understandings.
                                                <br /><br />7.3. Notices. All communications or notices permitted or required to be given or served under this Agreement shall be in writing via email or postal mailed to the addresses set forth on the first page of this Agreement.
                                                <br /><br />7.4. Counterparts. This Agreement may be executed in one or more counterparts, each of which shall constitute an original hereof, and all of which shall constitute one and the same document. Facsimile signatures on any such counterpart shall be binding as originals.
                                                <br /><br />IN WITNESS WHEREOF, the parties hereto have caused this Agreement to be executed by their respective duly authorized representative as of the Effective Date.

                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mt: 5 }} >
                                            <TextField disabled size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="ClientSign" name="ClientSign" placeholder='ClientSign' sx={{ mt: 2 }}   fullWidth />
                                            <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Print Name" name="Client Print Name" label="Print Name" value={agreement["Client Print Name"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Print Name' sx={{ mt: 1 }}   fullWidth />
                                            <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Title" name="Client Title" label="Title" value={agreement["Client Title"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Title' sx={{ mt: 1 }}   fullWidth />
                                            <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Company Name" name="Client Company Name" label="Company Name" value={agreement["Client Company Name"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Company Name' sx={{ mt: 1 }}   fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mt: 5 }}>
                                            <TextField disabled size='small' variant='standard' InputProps={{ style: styles.textfield }} InputLabelProps={{ style: styles.textfield }} color={'text'} id="MBMBSign" name="MBMBSign" placeholder='MBMBSign' sx={{ mt: 2 }}   fullWidth />
                                            <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Print Name" name="MBMB Print Name" label="Print Name" value={agreement["MBMB Print Name"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Print Name' sx={{ mt: 1 }}   fullWidth />
                                            <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="MBMB Title" name="MBMB Title" label="MBMB Title" value={agreement["MBMB Title"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Title' sx={{ mt: 1 }}   fullWidth />
                                            <TextField size='small' variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="MBMB Company Name" name="MBMB Company Name" label="Company Name" value={agreement["MBMB Company Name"]} onChange={(e) => setAgreement({ ...agreement, [e.target.name]: e.target.value })} placeholder='Company Name' sx={{ mt: 1 }}   fullWidth />
                                        </Grid>
                                    </Grid>
                                </Box>


                                <Box sx={{ m: 5, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <Button variant='contained' fullWidth
                                        disabled={checkLength(agreement, 11)}
                                        onClick={() => submitAgree( agreement)}>
                                        <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                            Submit
                                        </Typography>
                                    </Button>
                                </Box>

                            </AccordionDetails>
                        </Accordion>


                        <Accordion sx={{ m: 2, background: 'linear-gradient(90deg, #d5278f99 0%, #2f3d95ee 95.61%)', }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography component='h4' sx={{ fontSize: "2rem", color: dark ? '#fff' : '#000', fontFamily: 'Sofia', p: 2 }} >{`CLIENT INFO`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box color="#fff0" height={"max-content"} sx={{ mr: 'auto', ml: 'auto', p: 2, width: 'auto', maxWidth: '720px', overflow: 'auto' }}   >

                                    <Stepper activeStep={clientForm} orientation='vertical'  >

                                        <Step key={"BUSINESS DETAIL"}  >
                                            <StepLabel >
                                                <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>BUSINESS DETAIL</Typography>
                                            </StepLabel>

                                            <StepContent>
                                                {/* <Divider sx={{ mt: 10 }} ><Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>BUSINESS DETAIL</Typography></Divider> */}
                                                <Grid container spacing={2} rowSpacing={1} sx={{
                                                    width: 'auto', m: 0, overflow: 'auto',
                                                }}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Legal Business Name" name="Legal Business Name" label="Legal Business Name" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Legal Business Name'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' id="Country" name="Country" label="Country" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Country'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="STATE (If applicable)" name="STATE (If applicable)" label="State (If applicable)" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='State (If applicable)'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="City, State" name="City, State" label="City, State" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='City, State'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Zip Code (6 Digits)" name="Zip Code (6 Digits)" label="Zip Code (6 Digits)" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Zip Code (6 Digits)'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Phone" name="Phone" label="Phone" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Phone'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Fax Number" name="Fax Number" label="Fax Number" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Fax Number'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Business Address" name="Business Address" label="Business Address" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Business Address'   multilines="true" fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Business Owner" name="Business Owner" label="Business Owner" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Business Owner'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Owner Contact No" name="Owner Contact No" label="Owner Contact No" onChange={(e) => setBusiness({ ...business, [e.target.name]: e.target.value })} placeholder='Owner Contact No'   fullWidth /></Grid>

                                                </Grid>
                                                <Box sx={{ mb: 2, p: 2, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        color={'primary'}
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={checkLength(business, 10)}
                                                        onClick={() => { setClientForm(prev => prev + 1); }}
                                                    >
                                                        <Typography sx={{ color: !dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                            Next </Typography>
                                                    </Button>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                        <Step key={"MANAGEMENT"}  >
                                            <StepLabel >
                                                <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>MANAGEMENT</Typography>
                                            </StepLabel>

                                            <StepContent>
                                                {/* <Divider sx={{ mt: 10 }} ><Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>MANAGEMENT</Typography></Divider> */}
                                                <Grid container spacing={2} rowSpacing={1} sx={{
                                                    width: 'auto', m: 0, overflow: 'auto',
                                                }}>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Administrator" name="Administrator" label="Administrator" onChange={(e) => setManagement({ ...management, [e.target.name]: e.target.value })} placeholder='Administrator'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Administrator Contact No" name="Administrator Contact No" label="Administrator Contact No" onChange={(e) => setManagement({ ...management, [e.target.name]: e.target.value })} placeholder='Administrator Contact No'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Office Manager" name="Office Manager" label="Office Manager" onChange={(e) => setManagement({ ...management, [e.target.name]: e.target.value })} placeholder='Office Manager'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Agency Bill Representative" name="Agency Bill Representative" label="Agency Bill Representative" onChange={(e) => setManagement({ ...management, [e.target.name]: e.target.value })} placeholder='Agency Bill Representative'   fullWidth /></Grid>
                                                </Grid>

                                                <Box sx={{ mb: 2, p: 2, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        color={'primary'}
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={checkLength(management, 4)}
                                                        onClick={() => { setClientForm(prev => prev + 1); }}
                                                    >
                                                        <Typography sx={{ color: !dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                            Next </Typography>
                                                    </Button>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                        <Step key={"REQUIRED EMAILS"}  >
                                            <StepLabel >
                                                <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>REQUIRED EMAILS</Typography>
                                            </StepLabel>

                                            <StepContent>
                                                <Grid container spacing={2} rowSpacing={1} sx={{
                                                    width: 'auto', m: 0, overflow: 'auto',
                                                }}>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Billed Report Email" name="Billed Report Email" label="Billed Report Email" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Billed Report Email'  fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Eligibility Check Email" name="Eligibility Check Email" label="Eligibility Check Email" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Eligibility Check Email'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Coding Emails" name="Coding Emails" label="Coding Emails" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Coding Emails'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Invoicing Emails" name="Invoicing Emails" label="Invoicing Emails" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Invoicing Emails'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Confidential Information Emails" name="Confidential Information Emails" label="Confidential Information Emails" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Confidential Information Emails'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Billing Error Corrections and Inquiries Email" name="Billing Error Corrections and Inquiries Email" label="Billing Error Corrections and Inquiries Email" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Billing Error Corrections and Inquiries Email'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Date Received Medicare Certification" name="Date Received Medicare Certification" label="Date Received Medicare Certification" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Date Received Medicare Certification'   fullWidth /></Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} ><TextField variant='standard' InputProps={dark && { style: styles.textfield }} InputLabelProps={dark && { style: styles.textfield }} color={dark && 'text'} id="Date Received DDE Credentials" name="Date Received DDE Credentials" label="Date Received DDE Credentials" onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })} placeholder='Date Received DDE Credentials'   fullWidth /></Grid>

                                                </Grid>
                                                <Box sx={{ mb: 2, p: 2, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        color={'primary'}
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={checkLength(emails, 8)}
                                                        onClick={() => { setClientForm(prev => prev + 1); }}
                                                    >
                                                        <Typography sx={{ color: !dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                            Next </Typography>
                                                    </Button>
                                                </Box>
                                            </StepContent>
                                        </Step>

                                    </Stepper>
                                    <Box sx={{ m: 5, ml: 'auto', maxWidth: '720px', width: 'auto', mr: 'auto', display: 'flex', justifyContent: 'center' }}>
                                        <Button variant='contained' fullWidth
                                            disabled={checkLength(business, 10) || checkLength(management, 4) || checkLength(emails, 8)}
                                            onClick={() => submitClient(business, management, emails)}>
                                            <Typography sx={{ color: dark ? '#fff' : '#000', fontFamily: 'arial' }}>
                                                Submit
                                            </Typography>
                                        </Button>
                                    </Box>

                                </Box>

                                <LinearProgressWithLabel value={clientForm} total={3} color={'primary'} />
                            </AccordionDetails>
                        </Accordion>

                    </Box>





                </Grid>
            </Grid >
            <Box sx={{  width: '100%', position: 'absolute', top: 0,  zIndex: '50' }} >

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={submit}
                    >
                        {(!success && !errorrs) && <Typography ><CircularProgress color="inherit" /> Sending...</Typography>}
                        {success && <Alert
                            severity="success"
                            action={
                                <IconButton size='small' color="success" onClick={() => { window.location.href = "http://www.miamibeachmb.com/"; }}>
                                  <CheckCircleOutlineSharp color='success' size='small' />
                                </IconButton>
                              }
                            
                            sx={{ minWidth:'460px'}}
                        >
                              <AlertTitle>Success</AlertTitle>

                            Form Submited Successfully.
                        </Alert>}
                        {errorrs && <Alert
                            severity="error"
                            action={
                                <IconButton size='small' color="error" onClick={() => { window.location.href = "http://www.miamibeachmb.com/form"; }}>
                                  <ReplaySharp color='error' size='small' />
                                </IconButton>
                              }
                            
                            sx={{ minWidth:'460px'}}
                        >
                             <AlertTitle>Error</AlertTitle>
                            {errorMsg}
                        </Alert>}

                    </Backdrop>
            </Box >
        </ThemeProvider >
    )
}