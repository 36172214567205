import React, { useState, useRef } from 'react'
import Vector4 from "../../assets/images/vector4.webp";
import Vector5 from "../../assets/images/vector5.webp";
import Vector6 from "../../assets/images/vector6.webp";
import Vector7 from "../../assets/images/vector7.webp";
import Vector8 from "../../assets/images/vector8.webp";
import Vector9 from "../../assets/images/vector9.webp";
import emailjs from "@emailjs/browser";
import Form from "react-bootstrap/Form";
const medicalSpecialties = [
	'Primary care',
	'Family medicine',
	'Internal medicine',
	'Pediatrics',
	'OB/GYN',
	'General surgery',
	'Orthopedics',
	'Dermatology',
	'Psychiatry',
	'Cardiology',
	'Oncology',
	'Ophthalmology',
	'Radiology',
	'Urology',
	'Endocrinology'
];

function Practices() {
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);

	return (
		<div>
			<div className="services">
				<div className="services_section">
					<h2>OUR PRACTICES</h2>
					<p>
						MIAMI BEACH Medical Billing understands the unique demands of different medical specialties. Our approach is highly adaptable, ensuring that we meet the specific needs of your practice, regardless of your medical field. From primary care to specialized areas such as cardiology, radiology, and more, our services are tailored to your specialties. We're committed to delivering customized solutions that align with the nature of your practice, ensuring your financial success in your chosen field of healthcare.
					</p>
				</div>
				<ul className="services_cards row">
					{medicalSpecialties.map((x) =>
						<div key={x} className='col-sm-4'>
							<div className="practices_cards_row_1 mb-3">
								<div className="practices_card_inner">
									<div className="inner_section">
										<li>{x}</li>
									</div>
								</div>
							</div>
						</div>)
					}
				</ul>
				<hr />
				<div className="btn_wrapper">
					<button className="practiceCTA" onClick={handleShow}>
						<b>Contact us </b>
					</button>
				</div>

			</div>
		</div>
	)
}

export default Practices