


import React from 'react';
import { Button,  TextField, Typography,Grid,Box } from '@mui/material';
import { createTheme, ThemeProvider, } from '@mui/material/styles';
import logo from "../assets/images/mbmb-black.webp";
import myFooter from "../assets/images/myFooter.webp";
import { green, } from '@mui/material/colors';
import { initializeApp, } from "firebase/app";
import {  getAuth,  signInWithEmailAndPassword,   } from "firebase/auth";
import Form from './form';


const firebaseConfig = {
    apiKey: "AIzaSyBvdd19MIU-KD9q5T75uEK2-SAWzGrBLoA",
    authDomain: "mbmb-98c42.firebaseapp.com",
    databaseURL: "https://mbmb-98c42-default-rtdb.firebaseio.com",
    projectId: "mbmb-98c42",
    storageBucket: "mbmb-98c42.appspot.com",
    messagingSenderId: "8947764661",
    appId: "1:8947764661:web:2eb5d0d212f651ee7dddfc"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);








export default function SigninFunction() {
    const [currentUser, setCurrentUser] = React.useState(null);
    const [email, setEmail] = React.useState('Jasmel@medicalbillingmb.com');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState(false);






    const signInWithEmail = (email, password) => {
        signInWithEmailAndPassword(auth, email, password)
            .then(
                (userCredential) => {
                    const user = userCredential.user;
                    setCurrentUser(user);
                }
            )
            .catch((error) => {
                setError(error);
            });
    };





    const theme = createTheme({
        palette: {
            text: {
                main: '#fff',
                contrastText: green[50]
            },
            primary: {
                main: '#5D4037  ',
                contrastText: '#CCCCCC'
            },
            success: {
                main: green['900'],
                contrastText: '#000'
            }
        },
    });

    return (


        <ThemeProvider theme={theme} >

            {currentUser ? <Form /> :
                (
                    <Grid container sx={{
                        background: 'linear-gradient(-25deg, #60d8d4 0%, #209cff 95.61%)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100%',
                        pt: '250px'
                    }}>
                        <Box
                            sx={{
                                height: '350px',
                                width: '100%',
                                background: 'linear-gradient(90deg, #d5278f 0%, #2f3d95 95.61%)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'fixed',
                                top: 0

                            }}>
                            {/* <Grid item sx={{ mt: '35px' }}> */}
                            <Typography variant='h2' align='center' sx={{ color: '#fffb' }} >MIAMI BEACH Medical Billing</Typography>
                            {/* /  <img src={Footer} alt="" style={{ width: '200px', height: 'auto', backgroundColor: '#fffc', padding: '18px', borderRadius: '25px' }} /> */}
                            {/* </Grid> */}

                        </Box>

                        <Grid container item direction={'row'} sx={{ width: '460px', justifyContent: 'space-around', alignItems: 'center', backgroundColor: '#2f3d9521', pt: 1, pb: 1.5, pl: 3, pr: 3, borderRadius: '50px', boxShadow: '5px 5px 5px #d5278f55' }}>
                            <Grid item >
                                <img src={logo} alt="" style={{ width: 'auto', height: 80, marginTop: 15, }} />

                            </Grid>
                            <Grid item>
                                <div style={{ width: '300px', marginLeft: 'auto', marginRight: 'auto' }} >
                                    <TextField
                                        variant='standard'
                                        placeholder='Enter Passcode'
                                        label='Enter Passcode'
                                        onChange={(e) => setPassword(e.target.value)}
                                        error={error}
                                        color={error ? 'error' : 'primary'}
                                        helperText={error && 'Incorrect Passcode.'}
                                        fullWidth
                                        sx={{ m: 0.5 }}
                                    />
                                    <Button
                                        // startIcon={<SendSharp color='success' />}
                                        variant='text'
                                        color='success'
                                        fullWidth
                                        onClick={() => signInWithEmail(email, password)}
                                        sx={{ m: 0.5 }}

                                    >
                                        Access Form !
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>

                        <Box
                            sx={{
                                height: '100px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'fixed',
                                bottom: 0,

                            }}>

                            <img src={myFooter} alt="" style={{ width: 'auto', height: '35px' }} />

                        </Box>
                    </Grid>
                )}
        </ThemeProvider>
    )
}

